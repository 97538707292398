import React from "react";

function Solliciteren() {
  return (
    <section className="container mx-auto px-6 py-10  max-w-7xl   ">
      <div className="w-full lg:w-4/6">
        <h4 className="text-2xl text-black font-semibold font-display mb-6">
          Solliciteren?
        </h4>
        <p className="text-base mb-10 font-light ">
          Sluit jij als persoon goed aan op het profiel hierboven? Wil jij je
          eigen carrièrepad uitstippelen binnen Gladior? Wil je samen met
          collega’s en klanten werken aan een succesvolle online aanwezigheid?{" "}
          <span className="font-bold">
            Solliciteer dan direct door te mailen naar{" "}
            <a
              className="text-gladior-pink"
              href="mailto:personeelszaken@gladior.com"
            >
              personeelszaken@gladior.com
            </a>
          </span>
          .{" "}
        </p>
        <p>
          In het sollicitatieformulier verwachten wij dat je jouw{" "}
          <span className="font-bold"> motivatie brief en CV meestuurt</span>.
          Natuurlijk mag je ook je goed bijgewerkte LinkedIn profiel als CV
          versturen. Heb je nog vragen? Mail dan naar{" "}
          <a
            className="text-gladior-pink"
            href="mailto:personeelszaken@gladior.com"
          >
            personeelszaken@gladior.com
          </a>{" "}
          of bel ons gerust op{" "}
          <a className="text-gladior-pink" href="tel:+31538507090">
            053 850 70 90
          </a>
          . Een assessment kan onderdeel zijn van de procedure.
        </p>
        <br></br>
        <p className="font-bold">
          Wil je een open sollicitatie sturen? Dat kan ook! Mail dan je gegevens
          naar{" "}
          <a
            className="text-gladior-pink"
            href="mailto:personeelszaken@gladior.com"
          >
            personeelszaken@gladior.com
          </a>
          .
        </p>
        <br></br>
        <a
          href="mailto:personeelszaken@gladior.com"
          className="bg-transparent hover:bg-gladior-pink  text-gladior-pink  font-semibold hover:text-white py-2 px-4 border border-gladior-pink hover:border-transparent rounded"
        >
          Solliciteer nu
        </a>
      </div>
    </section>
  );
}
export default Solliciteren;
