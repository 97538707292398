import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import ReactMarkdown from "react-markdown";
import { HTMLContent } from "../components/Content";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SolliciterenSection from "../components/Solliciteren";

function HightLightBlock({ title, variant, color, children }) {
  return (
    <div
      className={`${
        color == "pink" ? "bg-gladior-pink " : "bg-gladior-black "
      } w-full p-12 text-white text-sm font-light lg:max-w-md xl:max-w-xl`}
    >
      <h3 className="text-3xl font-medium  font-display mb-6">{title}</h3>

      {children}
    </div>
  );
}

export const JobPageTemplate = ({ post }) => {
  return (
    <>
      <div className="flex justify-between flex-wrap">
        <section className="w-full lg:w-4/6 ">
          <h1 className="text-left mb-4 text-3xl sm:text-4xl md:text-5xl xl:text-4xl leading-tight   font-bold font-display font-display ">
            {post.id}
          </h1>
          <p className="pb-2 mb-8 mr-8 inline-block border-b-2 border-gladior-pink">
            {post.location}
          </p>
          <p className="pb-2 mb-8 inline-block border-b-2 border-gladior-pink">
            {post.hours}
          </p>

          <ReactMarkdown
            className="netlify-html job-html mt-6 leading-relaxed sm:text-lg md:text-xl xl:text-lg text-black"
            source={post.lead_paragraph}
          />

          <ReactMarkdown
            className="netlify-html job-html text-base mb-2 font-light pt-6"
            source={post.body1}
          />
        </section>
        <section className="w-full lg:w-1/4 mt-6 ">
          <a
            href="mailto:personeelszaken@gladior.com"
            className="bg-transparent hover:bg-gladior-pink  text-gladior-pink  font-semibold hover:text-white py-2 px-4 border border-gladior-pink hover:border-transparent rounded"
          >
            Solliciteer nu
          </a>
        </section>
      </div>

      <section className="w-full flex flex-wrap justify-between mt-12">
        <HightLightBlock title={post.activities_title} variant="block">
          <ReactMarkdown
            className="netlify-html job-html"
            source={post.activities}
          />
        </HightLightBlock>
        <HightLightBlock
          title={post.your_profile_title}
          variant="block"
          color="pink"
        >
          <ReactMarkdown
            className="netlify-html job-html"
            source={post.your_profile}
          />
        </HightLightBlock>
      </section>
      <section className="w-full lg:w-4/6  pt-6">
        <ReactMarkdown className="netlify-html job-html " source={post.body2} />
      </section>
      <ReactMarkdown className="netlify-html job-html" source={post.body} />
    </>
  );
};

JobPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const JobPage = ({ data }) => {
  const { jobYaml: post } = data;

  return (
    <>
      <SEO
        title={post.seo.meta_title}
        description={post.seo.meta_description}
        pathname={post.path}
      />
      <Layout
        title={post.title}
        subtitle={post.subtitle}
        heroBackgroundImage={post.hero_background_image}
      >
        <main className="container mx-auto px-6 py-10  max-w-7xl ">
          <JobPageTemplate
            contentComponent={HTMLContent}
            content={post.html}
            post={post}
          />
        </main>

        <SolliciterenSection />
      </Layout>
    </>
  );
};

JobPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default JobPage;

export const jobPageQuery = graphql`
  query JobPage($id: String!) {
    jobYaml(id: { eq: $id }) {
      activities
      activities_title
      body1
      body2
      department
      hero_background_image {
        childImageSharp {
          fluid(quality: 90, maxWidth: 4160) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      hours
      hours_short
      id
      lead_paragraph
      location
      seo {
        meta_description
        meta_title
      }
      templateKey
      title
      your_profile
      your_profile_title
    }
  }
`;
